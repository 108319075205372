@import '../../../assets/scss/variables';

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  .video-wrapper {
    width: 100%;
    max-width: 1680px;
  }
  .video {
    display: block;
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;


    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .inner {
    align-items: center;
    text-align: center;
    margin-top: $base*4.5;
    margin-bottom: $base*4.5;
  }
  p {
    img {
     width: 16px;
    }
  }
  @media #{$mobile} {
    .video {
      margin: 0;
    }
  }
}