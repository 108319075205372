@import '../../../assets/scss/variables';

.stepper {
  position: relative;
  .scroll {
    width: 10px;
    height: 50px;
    background-color: black;
    top: 0;
  }
  img {
    display: inline-block;
    width: 23px;
    height: 23px;
  }
  .scroll {
    display: block;
    background-color: $bg-section;
    width: 6px;
    position: absolute;
    height: 100%;
    border-radius: 3px;
    left: 50%;
    transform: translateX(-50%);
    > span {
      background-color: $dark-gold;
      height: $base*37;
      width: 6px;
      border-radius: 3px;
      position: absolute;
      top: 0;
    }
  }
  div {
    width: 50%;
    text-align: right;
    margin-bottom: $base*4.5;
    padding-right: $base*9;
    box-sizing: border-box;
    h4 {
      display: flex;
      flex-direction: row-reverse;
      img {
        margin-left: 10px;
        margin-right: -$base*4
      }
    }
    p,
    h5 {
      margin-left: auto;
    }
    &:nth-child(even) {
      margin-left: 50%;
      text-align: left;
      padding-left: $base*9;
      padding-right: 0;
      h4 {
        flex-direction: row;
        img {
          margin-right: 10px;
          margin-left: -$base*4;
        }
      }
      p,
      h5 {
        margin-left: 0;
      }
    }
  }
  @media #{$mobile} {
    .scroll {
      transform: none;
      left: 0;
    }
    div {
      width: 100%;
      margin-right: 0;
      padding-right: 0;
      margin-left: 0;
      padding-left: $base*6;
      text-align: left;
      h4 {
        flex-direction: row;
        img {
          margin-right: 10px;
          margin-left: -$base*3;
        }
      }
      &:nth-child(even) {
        width: 100%;
        margin-right: 0;
        padding-right: 0;
        margin-left: 0;
        padding-left: $base*6;
        h4 {
          img {
            margin-left: -$base*3;
          }
        }
      }
    }
  }
}
