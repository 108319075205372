.form-control {
  display: flex;
  flex-direction: column;
  margin-bottom: $base*3.75;
  label {
    margin-bottom: $base;
  }
  &.required {
    label {
      &:after {
        content: '*';
        margin-left: 5px;
        color: $error;
      }
    }
  }
}