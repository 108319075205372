@import '../../../assets/scss/variables';

.members {
  .requirements {
    .regular {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }
    .item {
      flex: 0 0 calc(33% - 20px);
      span {
        display: block;
        margin-bottom: $base*3;
        img {
          height: $base*3.5;
          width: $base*3.5;
          display: block;
          filter: invert(32%) sepia(1%) saturate(2927%) hue-rotate(356deg) brightness(98%) contrast(82%);
        }
      }
    }
  }

  @media #{$tablet} {
    .requirements {
      .item {
        flex: 0 0 calc(50% - 15px);
      }
    }
  }

  @media #{$mobile} {
    .requirements {
      .slick-list {
        padding-left: 0 !important;
      }
      .item {
        box-sizing: border-box;
        padding-left: 0;
        transition: padding-left 0.2s;
        p {
          padding-right: 25px;
        }
      }
    }
  }
}