@import '../../../assets/scss/variables';

.App {
  header {
    .language-switcher {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
  > main {
    padding-top: $base*9;
    .dark {
      background-color: $bg-section;
    }
    > section {
      display: flex;
      justify-content: center;
      .inner {
        display: flex;
        flex-direction: column;
        flex: 1 1 100%;
        max-width: 1680px;
        margin: $base*7 $base*7.5;
        width: calc(100% - #{$base}*15)

      }

    }
  }
  @media #{$mobile} {
    > main {
      > section {
        .inner {
          margin: $base*5.25 $base*3.25;
          width: calc(100% - #{$base}*6.5)
        }
      }
    }
  }
}