@import '../variables';

select {
  -webkit-appearance: none;
  border-radius: $base*0.5;
  font-size: 14px;
  padding: $base*2 $base*4.5 $base*2 $base*2;
  border: 1px solid $dark-gold;
  background: url("../../../assets/img/drop-down.png") no-repeat;
  background-position: right 15px top 20px;
}
