@import '../variables';

.btn {
  background-color: $dark-gold;
  height: $base*6.25;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid $dark-gold;
  color: $light;
  border-radius: $base*0.5;
  width: 100%;
  font-family: 'Avenir Next', sans-serif;
  transition:
    color 0.4s,
    background-color 0.4s,
    box-shadow 0.4s,
    border-color 0.4s;
  &:hover {
    color: $dark-gold;
    background-color: $light;
    border-color: $dark-gold
  }
}