@import-normalize;

@import 'variables';
@import 'scaffolding';
@import 'type';
@import 'fonts';

/* components */
@import 'components/button';
@import 'components/form-control';
@import 'components/input-text';
@import 'components/select';