@import '../../../assets/scss/variables';

.benefits {
  .img {
    img {
      width: 100%;
    }
  }
  .benefits-nav {
    font-size: 11px;
    line-height: 15px;
    .slick-slide {
      padding: $base*2.5 0;
      text-transform: uppercase;
      margin-right: $base*3.5;
      border-bottom: 3px solid transparent;
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
      &:last-child {
        margin-right: 0;
      }
      @media #{$custom-slider-beneficts} {
        cursor: pointer;
        &:active {
          cursor: pointer;
        }
      }
    }
    .slick-current {
      border-bottom: 3px solid $dark-gold;
    }
  }
  .benefits-content {
    padding: $base*5 0 0;
    .content {
      display: flex !important;
      align-items: flex-start;
      gap: $base*5;
      h4 {
        flex: 0 0 40%;
        max-width: 680px;
        letter-spacing: 1.2px;
      }
    }
  }
  @media #{$mobile} {
    .benefits-nav {
      .slick-slide {
        max-width: 120px;
      }
    }
    .benefits-content {
      .content {
        flex-direction: column;
        gap: 0;
        h4 {
          flex: 1 1 auto;
        }
      }
    }
  }
}
