@import '../../../assets/scss/variables';

.App {
  > header {
    background-color: $bg-section;
    height: $base*9;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    width: 100%;
    z-index: 100;
    img {
      width: 150px;
    }
    span {
      display: block;
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
}