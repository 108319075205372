@import '../variables';

input[type='text'],
input[type='password'] {
  border-radius: $base*0.5;
  font-size: 14px;
  padding: $base*2;
  border: 1px solid $dark-gold;
  &.error {
    border-color: red;
  }
}
