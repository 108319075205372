
$base: 8px;

/* Colores */
$light: #FFFFFF;

$bg-section: #F2EEEC;

$dark-gold: #5E5952;

$text: #383531;

$error: #ff0000;

/* Responsive */
$mobile: 'only screen and (max-width: 767px)';
$tablet: 'only screen and (min-width: 768px)  and (max-width: 1024px)';
$desktop: 'only screen and (min-width: 1025px)';

// Variable para el slider de beneftis cambiar tambien en beneficts.js buscando el nombre de esta variable ($custom-slider-beneficts)
$custom-slider-beneficts: 'only screen and (min-width: 1210px)';