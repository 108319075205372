@import '../../../assets/scss/variables';

.faqs {
  dl {
    margin: 0;
    padding: 0;
    div {
      border-bottom: 1px solid $dark-gold;
      padding-top: $base*3;
      padding-bottom: $base*3;

      dt {
        font-size: 21px;
        line-height: 25px;
        letter-spacing: 0.34px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 0 $base*12.5 0 0;
        position: relative;
        cursor: pointer;

        &:after {
          content: '';
          display: block;
          background-image: url("./../../../assets/img/icons/ico-plus.svg");
          width: $base*2.25;
          height: $base*2.25;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      dd {
        overflow: hidden;
        max-height: 0;
        transition: all 0.3s ease-out;
        font-size: 14px;
        line-height: 21px;
        margin: 0;
        box-sizing: border-box;
        ul {
          li {
            margin-bottom: $base*2;
          }
        }

      }
      &.open {

        dt {
          &:after {
            background-image: url("./../../../assets/img/icons/ico-minus.svg");
          }
        }
        dd {
          margin: $base*3 0 0;
          max-height: 500px;
        }
      }
    }
  }
  @media #{$mobile} {
    div {
      dl {
        dt {
          padding-right: $base*5;
          font-size: 15px;
          line-height: 21px;
        }
      }
    }
  }
}
