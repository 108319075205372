h1 {
  font-family: 'Land Rover Web', sans-serif;
  font-size: 38px;
  line-height: 38px;
  margin: 0 0 $base*3.25;
  font-weight: 700;
  letter-spacing: 3px;
  color: $dark-gold;
}

h2 {
  text-transform: uppercase;
  font-family: 'Land Rover Web', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  margin: 0 0 $base*3.5 0;
  letter-spacing: 3px;
  color: $dark-gold;
  & + p {
    &:last-child {
      margin-bottom: $base*10;
    }
  }
  @media #{$mobile} {
    & + p {
      margin-bottom: $base*5;
    }
  }
  &.no-caps {
    text-transform: none;
  }
}

h3 {
  font-family: 'Avenir Next', sans-serif;
  font-size: 21px;
  line-height: 29px;
  letter-spacing: 0.34px;
  max-width: $base*85;
  font-weight: normal;
  border-bottom: 0;
  margin: 0 0 $base*3.5 0;
}

h4 {
  font-family: 'Land Rover Web', sans-serif;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  margin: 0 0 18px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $dark-gold;
}

h5 {
  font-family: 'Avenir Next', sans-serif;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.34px;
  max-width: $base*85;
  font-weight: normal;
  border-bottom: 0;
  margin: 0;
}

p {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.34px;
  max-width: $base*125;
  margin: 0 0 $base*2.5 0;
  &:last-child {
    margin-bottom: 0;
  }
}